import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~components/button';
import { ButtonColors, SizeTypes } from '~constants/components';
import PageUrls from '~constants/page-urls';
import UserMenu from '~components/widgets/user-menu';
import request from '~libs/request';
import HttpErrorHandler from '~components/http-error-handler';
import { useUserInfo } from '~hooks/use-user';
import WalletContainer from '~containers/wallet-container';
import { WalletWidgetTypeEnum } from '~constants/etc';

const AuthorizedNavigation: FC = () => {
  const { t } = useTranslation('common');
  const { data: userInfo, mutate: mutateUser } = useUserInfo();

  const handleSwitchWallet = async (walletId: string): Promise<void> => {
    try {
      await request({
        url: `/wallets/${walletId}/select`,
        method: 'PUT',
      });
      mutateUser();
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  return (
    <div className="flex w-full items-center gap-2">
      <WalletContainer
        activeWalletId={userInfo?.selectedWallet.objectId || ''}
        handleSwitchWallet={handleSwitchWallet}
        type={WalletWidgetTypeEnum.Navigation}
        wallets={userInfo?.wallets}
      />
      <div className="flex w-full items-center gap-1">
        <Button
          block
          className="w-full min-w-max border-opacity-30 px-2 text-sm md:min-w-[100px] md:px-4"
          color={ButtonColors.Primary}
          href={PageUrls.FinancialDeposit}
          size={SizeTypes.Small}
        >
          {t('deposit')}
        </Button>
        <Button
          block
          className="w-full min-w-max border border-white border-opacity-30 px-2 text-sm md:min-w-[100px] md:px-4"
          color={ButtonColors.Transparent}
          href={PageUrls.FinancialWithdraw}
          size={SizeTypes.Small}
        >
          {t('withdraw')}
        </Button>
      </div>
      <UserMenu />
    </div>
  );
};

export default AuthorizedNavigation;
