'use client';

import type { FC } from 'react';
import { useState } from 'react';
import MenuBottom from '~containers/navbar/menu-bottom';
import MenuBar from '~containers/navbar/menubar';
import TopBar from '~containers/navbar/top-bar';
import useIsMobile from '~libs/use-is-mobile';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';

const Navbar: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
}> = ({ agentInfo, region }) => {
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const isMobile = useIsMobile();

  return (
    <div className="relative z-20">
      <TopBar
        agentInfo={agentInfo}
        menuActive={menuActive}
        region={region}
        setMenuActive={setMenuActive}
      />
      {isMobile ? <MenuBottom /> : <MenuBar />}
    </div>
  );
};

export default Navbar;
