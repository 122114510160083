'use client';

import type { FC, PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import type { UserInfoInterface } from '../../../types/user';
import Navbar from '~components/layouts/navbar';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import LiveChatProvider from '~contexts/live-chat-provider';

const LayoutClient: FC<
  PropsWithChildren & {
    agentInfo: AgentInfoResponseInterface;
    userInfo: UserInfoInterface | undefined;
    region: string;
    locale: string;
  }
> = ({ children, agentInfo, userInfo, region, locale }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY!}
    >
      <LiveChatProvider locale={locale} userInfo={userInfo}>
        <Navbar agentInfo={agentInfo} region={region} />
        <div className="mx-auto mt-4 w-full max-w-[1920px] pb-24 md:mt-8 md:pb-0">
          {children}
        </div>
      </LiveChatProvider>
    </GoogleReCaptchaProvider>
  );
};

export default LayoutClient;
