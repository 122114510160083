import buttonFill from './button-fill';
import buttonGhost from './button-ghost';
import { SizeTypes, ButtonColors, ButtonVariants } from '~constants/components';

export interface ButtonStylesType {
  defaultProps?: {
    size: SizeTypes;
    color: ButtonColors;
    block: boolean;
    className: string;
    variant: ButtonVariants.Fill | ButtonVariants.Ghost;
  };
  valid?: {
    variants: string[];
    sizes: string[];
    colors: string[];
  };
  styles: {
    base: {
      initial: {
        position: string;
        fontWeight: string;
        borderRadius: string;
        disabled: string;
        focus: string;
      };
      block: {
        display: string;
        width: string;
        align: string;
      };
      loading: {
        cursor: string;
      };
      loadingAnimation: {
        initial: string;
        before: string;
        after: string;
      };
    };
    sizes: Record<
      SizeTypes,
      {
        fontWeight?: string;
        width: string;
        height: string;
        fontSize: string;
        py: string;
        px: string;
      }
    >;
    variants: {
      [ButtonVariants.Fill]: Record<
        ButtonColors,
        {
          background: string;
          color: string;
          border?: string;
        }
      >;
      [ButtonVariants.Ghost]: Record<
        string,
        {
          border: string;
          color: string;
        }
      >;
    };
  };
}

export const button: ButtonStylesType = {
  defaultProps: {
    size: SizeTypes.Medium,
    color: ButtonColors.Primary,
    block: false,
    className: '',
    variant: ButtonVariants.Fill,
  },
  valid: {
    variants: Object.values(ButtonVariants),
    sizes: Object.values(SizeTypes),
    colors: Object.values(ButtonColors),
  },
  styles: {
    base: {
      initial: {
        position:
          'flex items-center justify-center gap-2 transition duration-300',
        fontWeight: 'font-semibold',
        borderRadius: 'rounded-3xl',
        disabled:
          'disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed',
        focus: 'focus:outline-none',
      },
      block: {
        display: 'flex',
        width: 'w-full',
        align: 'items-center justify-center',
      },
      loading: {
        cursor: 'flex cursor-not-allowed',
      },
      loadingAnimation: {
        initial:
          'relative w-[10px] h-[10px] rounded-full animate-dot-elastic bg-current',
        before:
          'before:bg-current before:inline-block before:absolute before:w-[10px] before:h-[10px] before:rounded-full before:-left-[15px] before:animate-dot-elastic-before',
        after:
          'after:bg-current after:inline-block after:absolute after:w-[10px] after:h-[10px] after:rounded-full after:left-[15px] after:animate-dot-elastic-after',
      },
    },
    sizes: {
      [SizeTypes.Small]: {
        fontWeight: 'font-normal',
        width: 'min-w-[100px]',
        height: 'min-h-[30px]',
        fontSize: 'text-xs',
        py: 'py-2',
        px: 'px-4',
      },
      [SizeTypes.Medium]: {
        width: 'min-w-[100px]',
        height: 'min-h-[50px]',
        fontSize: 'text-md',
        fontWeight: 'font-normal',
        py: 'py-2',
        px: 'px-4',
      },
      [SizeTypes.Large]: {
        width: 'min-w-[100px]',
        height: 'min-h-[70px]',
        fontSize: 'text-lg',
        py: 'py-2',
        px: 'px-4',
      },
    },
    variants: {
      [ButtonVariants.Fill]: buttonFill,
      [ButtonVariants.Ghost]: buttonGhost,
    },
  },
};

export default button;
