import { NoteColor } from '~constants/etc';

const NoteColors: object = {
  [NoteColor.Default]: {
    background: 'bg-dark',
    text: 'text-yellow',
  },
  [NoteColor.Success]: {
    background: 'bg-dark',
    text: 'text-success',
  },
  [NoteColor.Danger]: {
    background: 'bg-dark',
    text: 'text-danger',
  },
  [NoteColor.Ghost]: {
    background: 'bg-dark',
    text: 'text-light',
  },
};

export default NoteColors;
