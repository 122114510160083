import type { ContactInterface } from '../types/contact';
import request from '~libs/request';
import useRequest from '~hooks/use-request';

export interface GetContactResponse {
  contacts: ContactInterface;
}

export const useContact = (language: string) => {
  return useRequest<ContactInterface>({
    url: '/contacts',
    method: 'GET',
    params: {
      language,
    },
  });
};

export const getContactsServer = (
  language: string,
): Promise<ContactInterface> => {
  return request({
    url: '/contacts',
    method: 'GET',
    params: {
      language,
    },
  });
};
