import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { useMemo, type FC } from 'react';
import { parseCookies } from 'nookies';
import PageUrls from '~constants/page-urls';
import { useContact } from '~hooks/use-contact';
import { ContactTypeEnum, DEFAULT_LANG } from '~constants/etc';

const CustomerService: FC = () => {
  const { t } = useTranslation('common');
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || DEFAULT_LANG;
  const { data } = useContact(locale);

  const contactMapped = useMemo(() => {
    const defaultValue = [
      {
        text: t('customer_service'),
        icon: '/images/icon/telegram-icon.svg',
        href: PageUrls.Contact,
      },
    ];
    if (!data) {
      return defaultValue;
    }
    const discordData = data.contacts.find(
      (res) => res.contactType === ContactTypeEnum.Discord,
    );
    if (discordData) {
      return [
        ...defaultValue,
        {
          text: t('community'),
          icon: '/images/icon/discord-icon.svg',
          href: discordData.link,
        },
      ];
    }
    return defaultValue;
  }, [data]);

  return (
    <div className="flex items-center justify-center gap-x-6">
      {contactMapped.map((item, i) => {
        return (
          <Link
            className="flex items-center gap-x-2 text-white"
            href={item.href}
            key={i}
            target="_blank"
          >
            <Image
              alt=""
              draggable={false}
              height={24}
              src={item.icon}
              width={24}
            />
            <span>{item.text}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default CustomerService;
