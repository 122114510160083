'use client';

import Image from 'next/image';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AffiliateFilled,
  AnnounceFilled,
  CasinoFilled,
  CustomerServiceFilled,
  HomeFilled,
  PromotionFilled,
  SlotFilled,
  SportFilled,
} from '@packages/icons-react';
import { cn } from 'class-merge';
import { parseCookies } from 'nookies';
import { delay } from 'lodash';
import type { ProviderCasinoResApiInterface } from '../../types/casino';
import type { ProviderSportResApiInterface } from '../../types/sport';
import { ProviderEnum } from '~constants/menus';
import ProviderCard from '~components/card/provider-card';
import PageUrls from '~constants/page-urls';
import Marquee from '~components/marquee';
import { useAllGames, useGamingInfoHelper } from '~hooks/use-gaming';
import { useCasinos, useCasinosInfoHelper } from '~hooks/use-casinos';
import { useSportsbook, useSportsbookInfoHelper } from '~hooks/use-sports-book';
import { useRouter } from '~hooks/use-router';
import {
  DEFAULT_CURRENCY,
  ProviderTypeEnum,
  S3_BUCKET_IMAGES,
} from '~constants/etc';
import HttpErrorHandler from '~components/http-error-handler';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import objectToQueryString from '~libs/object-to-query-string';
import { encrypt } from '~libs/encrypt';
import { onOpenSportURL } from '~libs/open-url';
import { ClientSideRender } from '~components/layouts/client-side-render';
import { useDialog } from '~hooks/use-dialog';
import UnauthorizedAlertWidget from '~components/widgets/unauthorized-alert-widget';

const MenuBar: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { data: casinos } = useCasinos();
  const { data: gaming } = useAllGames();
  const { data: sports } = useSportsbook();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const { getCasinoProviderImage } = useCasinosInfoHelper();
  const { getGamingProviderImage } = useGamingInfoHelper();
  const { getSportProviderImage } = useSportsbookInfoHelper();

  const [dialog, dialogContext] = useDialog();
  const [menuEnter, setMenuEnter] = useState<string | any>();
  const [menuActive, setMenuActive] = useState<string>('');
  const [subMenuActive, setSubMenuActive] = useState(false);
  const currentPathname =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const activePathName = currentPathname.replace(/^\/[a-z]{2}(\/|$)/, `$1`);
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;

  const menuData: {
    title: string;
    icon: ReactNode;
    action?: string;
    path?: string;
    key: string;
    subMenu: boolean;
    disabled?: boolean;
    isNew?: boolean;
  }[] = [
    {
      title: t('common:menu.home'),
      icon: <HomeFilled className="text-xl 2xl:text-[32px]" />,
      action: PageUrls.Home,
      path: '',
      key: 'home',
      subMenu: false,
    },
    {
      title: t('common:menu.sportsbook'),
      icon: <SportFilled className="text-xl 2xl:text-[32px]" />,
      key: ProviderEnum.SPORT,
      subMenu: true,
    },
    {
      title: t('common:menu.casino'),
      icon: <CasinoFilled className="text-xl 2xl:text-[32px]" />,
      key: ProviderEnum.CASINO,
      subMenu: true,
    },
    {
      title: t('common:menu.gaming'),
      icon: <SlotFilled className="text-xl 2xl:text-[32px]" />,
      key: ProviderEnum.GAMING,
      subMenu: true,
    },
    {
      title: t('common:menu.affiliate'),
      icon: <AffiliateFilled className="text-xl 2xl:text-[32px]" />,
      action: PageUrls.Affiliate,
      path: '/affiliate',
      key: 'affiliate',
      subMenu: false,
    },
    {
      title: t('common:menu.promotion'),
      icon: <PromotionFilled className="text-xl 2xl:text-[32px]" />,
      action: PageUrls.FinancialDepositPromotion,
      path: '/deposit/promotion',
      key: 'promotion',
      subMenu: false,
    },
    {
      title: t('common:menu.announce'),
      icon: <AnnounceFilled className="text-xl 2xl:text-[32px]" />,
      action: PageUrls.Announcement,
      path: '/announcement',
      key: 'announce',
      subMenu: false,
    },
    {
      title: t('common:menu.customer_service'),
      icon: <CustomerServiceFilled className="text-xl 2xl:text-[32px]" />,
      action: PageUrls.Contact,
      path: '/contact',
      key: 'customer-service',
      subMenu: false,
    },
  ];

  useEffect(() => {
    const menuActiveata =
      menuData.find((ap) => ap.path === activePathName)?.path ?? '';
    setMenuActive(menuActiveata);
  }, [activePathName]);

  const handleOpenCasino = (game: ProviderCasinoResApiInterface): void => {
    if (!userInfo) {
      return dialog.content({
        children: <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />,
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Casino,
        providerId: game.id,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenSportsbook = async (
    game: ProviderSportResApiInterface,
  ): Promise<void> => {
    if (!userInfo) {
      return dialog.content({
        children: <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />,
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(game.id, currencyFiltered, walletInfo?.objectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const casinoProviderMapped = useMemo(() => {
    if (!casinos) {
      return [];
    }
    return casinos.map((res) => {
      const images = getCasinoProviderImage(res.id);
      return {
        ...res,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
        action: () => {
          handleOpenCasino(res);
        },
      };
    });
  }, [casinos, walletInfo, locale]);

  const gamingProviderMapped = useMemo(() => {
    if (!gaming) {
      return [];
    }
    return gaming.map((res) => {
      const images = getGamingProviderImage(res.id);
      return {
        ...res,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
        action: () => router.push(`${PageUrls.Gaming}/${res.id}`),
      };
    });
  }, [gaming]);

  const sportsbookProviderMapped = useMemo(() => {
    if (!sports) {
      return [];
    }
    return sports.map((res) => {
      const images = getSportProviderImage(res.id);
      return {
        ...res,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${res.id}.webp`,
        action: () => {
          void handleOpenSportsbook(res);
        },
      };
    });
  }, [sports, walletInfo, locale]);

  const providers = useMemo(() => {
    if (Object.values(ProviderEnum).includes(menuEnter)) {
      return {
        [ProviderEnum.CASINO]: casinoProviderMapped,
        [ProviderEnum.GAMING]: gamingProviderMapped,
        [ProviderEnum.SPORT]: sportsbookProviderMapped,
      };
    }
    return [];
  }, [menuEnter]);

  const handleMenuEnter = (key: string, subMenu: boolean) => {
    setMenuEnter(key);
    setSubMenuActive(subMenu);
  };
  const handleMenuLeave = (): void => {
    setMenuEnter('home');
    setSubMenuActive(false);
  };

  return (
    <div className="bg-dark h-[88px] w-full">
      {dialogContext}
      <div className="relative mx-auto flex h-full w-full max-w-[1920px] items-center justify-between gap-x-10 px-10 xl:gap-x-20 xl:px-[100px]">
        <div>
          <Image
            alt="logo"
            draggable={false}
            height={80}
            priority
            src={`${S3_BUCKET_IMAGES}/logo.webp`}
            width={120}
          />
        </div>
        <div className="flex w-full items-center justify-around gap-4">
          {menuData.map((item, i) => {
            return (
              <button
                className={cn(
                  'relative flex flex-col items-center justify-center gap-1',
                  menuActive === item.path ? 'text-primary' : 'text-white',
                  'hover:text-primary',
                  item.disabled && 'opacity-10',
                )}
                disabled={item.disabled}
                key={i}
                onClick={() => {
                  item.action ? router.push(item.action) : null;
                  setMenuActive(item.path ?? '');
                }}
                onMouseEnter={() => {
                  handleMenuLeave();
                  delay(() => {
                    handleMenuEnter(item.key, item.subMenu);
                  }, 10);
                }}
              >
                {item?.isNew ? (
                  <div className="bg-danger absolute -right-2.5 bottom-8 rounded px-1 text-[8px] text-white">
                    {t('new')}
                  </div>
                ) : null}
                <span className="text-[32px]">{item.icon}</span>
                {item.title}
              </button>
            );
          })}
        </div>
        <div
          className={cn(
            subMenuActive
              ? 'visible top-full bg-opacity-80'
              : 'invisible top-3/4 opacity-0',
            'bg-dark absolute left-0 min-h-40 w-full py-5 transition-all duration-300',
            'z-10 flex flex-wrap items-center justify-center gap-4 px-10 xl:px-[100px]',
          )}
          onMouseLeave={handleMenuLeave}
        >
          {providers[menuEnter]?.map((item, i) => (
            <ClientSideRender key={i}>
              <ProviderCard
                image={item.hero}
                logo={item.logo}
                onOpenGame={() => {
                  item.action();
                  handleMenuLeave();
                }}
                title={item.title}
                type={item.meta.category}
              />
            </ClientSideRender>
          ))}
        </div>
        <div
          className={cn(
            'absolute -bottom-8 left-0 z-0 w-full bg-black',
            subMenuActive && 'invisible',
          )}
        >
          <Marquee text={t('common:announce')} />
        </div>
      </div>
    </div>
  );
};
export default MenuBar;
