import noteColors from './note-colors';
import { NoteColor } from '~constants/etc';

export interface NoteStylesType {
  defaultProps?: {
    color?: NoteColor;
    className?: string;
  };
  valid?: {
    colors?: NoteColor[];
  };
  styles: {
    base: {
      container: {
        display: string;
        width: string;
        gap: string;
        rounded: string;
        background: string;
        padding: string;
        text: string;
      };
      icon: {
        display: string;
        justify: string;
        text: string;
      };
      text: {
        grow: string;
      };
    };
    colors: typeof noteColors;
  };
}

export const note: NoteStylesType = {
  defaultProps: {
    color: NoteColor.Default,
    className: '',
  },
  valid: {
    colors: [NoteColor.Default, NoteColor.Success, NoteColor.Danger],
  },
  styles: {
    base: {
      container: {
        display: 'flex',
        width: 'w-full',
        gap: 'gap-2',
        rounded: 'rounded-2xl',
        background: 'bg-success',
        padding: 'p-4',
        text: 'text-success',
      },
      icon: {
        display: 'flex',
        justify: 'justify-start',
        text: 'text-xl',
      },
      text: {
        grow: 'grow text-xs sm:text-sm',
      },
    },
    colors: noteColors,
  },
};

export default note;
