import { ButtonColors } from '~constants/components';

const buttonFill = {
  [ButtonColors.Default]: {
    background: 'bg-secondary',
    color: 'text-white',
  },
  [ButtonColors.Primary]: {
    background: 'bg-primary',
    color: 'text-dark',
  },
  [ButtonColors.Success]: {
    background: 'bg-success',
    color: 'text-white',
  },
  [ButtonColors.Danger]: {
    background: 'bg-danger',
    color: 'text-white',
  },
  [ButtonColors.Transparent]: {
    background: 'bg-transparent',
    color: 'text-white',
  },
};

export default buttonFill;
