'use client';

import { MenuFilled } from '@packages/icons-react';
import Image from 'next/image';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { cn } from 'class-merge';
import useIsMobile from '~libs/use-is-mobile';
import useAuth from '~hooks/use-auth';
import AuthorizedNavigation from '~components/layouts/navbar/authorized-navigation';
import CustomerService from '~components/layouts/navbar/customer-service';
import ChangeLanguageMenu from '~components/widgets/change-language';
import UnauthorizedNavigation from '~components/layouts/navbar/unauthorized-navigation';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import { S3_BUCKET_IMAGES } from '~constants/etc';

interface TopBarProps {
  agentInfo: AgentInfoResponseInterface;
  region: string;
  setMenuActive: (active: boolean) => void;
  menuActive: boolean;
}

const TopBar: FC<TopBarProps> = ({
  agentInfo,
  region,
  setMenuActive,
  menuActive,
}) => {
  const isMobile = useIsMobile();
  const { isLoggedIn } = useAuth();
  const [initialRenderComplete, setInitialRenderComplete] = useState(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  if (!initialRenderComplete) return null;

  if (isMobile) {
    return (
      <div className="min-h-[60px] w-full px-4">
        <div className="grid select-none grid-cols-3 items-center justify-between">
          <div className="text-light cursor-pointer text-2xl">
            {isLoggedIn ? (
              <MenuFilled
                onClick={() => {
                  setMenuActive(!menuActive);
                }}
              />
            ) : null}
          </div>
          <Image
            alt="logo"
            className="mx-auto"
            draggable={false}
            height={60}
            priority
            src={`${S3_BUCKET_IMAGES}/logo.webp`}
            width={90}
          />
          <div className="text-right">
            <ChangeLanguageMenu />
          </div>
        </div>
        <div
          className={cn(
            'transform transition-all duration-300',
            menuActive
              ? 'visible h-10 w-full scale-100'
              : 'invisible h-0 w-0 scale-0',
          )}
        >
          {isLoggedIn ? (
            <AuthorizedNavigation />
          ) : (
            <UnauthorizedNavigation
              agentInfo={agentInfo}
              className="my-2"
              region={region}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto flex h-14 max-w-[1920px] items-center justify-between px-[4%]">
      <CustomerService />
      <div className="flex items-center gap-2">
        {isLoggedIn ? (
          <AuthorizedNavigation />
        ) : (
          <UnauthorizedNavigation agentInfo={agentInfo} region={region} />
        )}
        <ChangeLanguageMenu />
      </div>
    </div>
  );
};

export default TopBar;
