import {
  AffiliateFilled,
  AnnounceFilled,
  CustomerServiceFilled,
  HomeFilled,
  PromotionFilled,
} from '@packages/icons-react';
import { cn } from 'class-merge';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageUrls from '~constants/page-urls';
import useAuth from '~hooks/use-auth';
import { useRouter } from '~hooks/use-router';

const MenuBottom = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const [menuActive, setMenuActive] = useState('');
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const currentPathname = window.location.pathname;
    const activePathName = currentPathname.replace(/^\/[a-z]{2}(\/|$)/, `$1`);
    setMenuActive(activePathName);
  }, []);

  const menuData = [
    {
      title: t('common:menu.home'),
      icon: <HomeFilled />,
      action: PageUrls.Home,
      path: '',
      key: 'home',
      subMenu: false,
    },

    {
      title: t('common:menu.affiliate'),
      icon: <AffiliateFilled />,
      action: PageUrls.Affiliate,
      path: '/affiliate',
      key: 'affiliate',
      subMenu: false,
    },
    {
      title: t('common:menu.promotion'),
      icon: <PromotionFilled />,
      action: PageUrls.FinancialDepositPromotion,
      path: '/promotions',
      key: 'promotion',
      subMenu: false,
    },
    {
      title: t('common:menu.announce'),
      icon: <AnnounceFilled />,
      action: PageUrls.Announcement,
      path: '/announce',
      key: 'announce',
      subMenu: false,
    },
    {
      title: t('common:menu.customer_service'),
      icon: <CustomerServiceFilled />,
      action: PageUrls.Contact,
      path: '/customer-service',
      key: 'customer-service',
      subMenu: false,
    },
  ];

  const handleClickMenu = (action) => {
    router.push(action);
  };
  if (!isLoggedIn) return null;
  return (
    <div className="bg-dark fixed bottom-0 left-0 h-[72px] w-full">
      <div className="flex h-full w-full justify-evenly">
        {menuData.map((item) => {
          const active = item.path === menuActive;
          return (
            <button
              className={cn(
                active ? 'text-primary' : 'text-light',
                'flex flex-col items-center justify-center gap-y-1 text-3xl',
              )}
              key={item.key}
              onClick={() => {
                handleClickMenu(item.action);
                setMenuActive(item.path);
              }}
            >
              {item.icon}
              <span
                className={cn(
                  active ? 'text-primary' : 'text-white',
                  'text-xs capitalize',
                )}
              >
                {item.title}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
export default MenuBottom;
