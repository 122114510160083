'use client';

import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import DynamicMenu from '~components/widgets/dynamic-menu';
import { LanguageEnum } from '~constants/etc';
import { useRouter } from '~hooks/use-router';

const languageDetails = {
  [LanguageEnum.TH]: { name: 'ไทย', icon: '/images/svg/flags/th.svg' },
  [LanguageEnum.EN]: { name: 'English', icon: '/images/svg/flags/en.svg' },
  [LanguageEnum.VI]: { name: 'Tiếng Việt', icon: '/images/svg/flags/vi.svg' },
};

const languages = Object.values(LanguageEnum).map((action) => ({
  action,
  ...languageDetails[action],
}));

const ChangeLanguageMenu: FC = () => {
  const router = useRouter();
  const { i18n } = useTranslation();

  const onChangeLang = async (langCode: string): Promise<void> => {
    await i18n.changeLanguage(langCode);
    const currentPathname = window.location.pathname;
    const newPathname = currentPathname.replace(
      /^\/[a-z]{2}(\/|$)/,
      `/${langCode}$1`,
    );
    router.push(newPathname, { scroll: false });
  };

  return (
    <DynamicMenu
      className="w-[60px]"
      itemActive={i18n.language ?? LanguageEnum.TH}
      items={languages}
      onChange={onChangeLang}
    />
  );
};

export default ChangeLanguageMenu;
